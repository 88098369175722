$spare-color: #1cb4bc;
$spare-light: #f2fcfb;
$dark-color: #383838;
$danger-color: #ef4643;
$sales-color: #00b53e;
$loadup-color: #29a1e6;
$spare-gradient: #1c7fbc;
$bronze-color: #cd7f32;
$silver-color: #a9a9a9;
$gold-color: #daa520;
$background-color: #fafafa;
$spare-border-form: #e7e7e7;
$spare-hover: #bce9ea;

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only required color in the map.

$mainAppGradient: linear-gradient(to right, #00a0c3, #00c7b8);
$mainAppGradientHover: linear-gradient(to right, #3c91c5, #3cbfc5);
$mainAppGradientToBottom: linear-gradient(to bottom, #00a0c3, #00c7b8);
$successGradient: linear-gradient(to right, #2ec658, #2dc55f);
$transactionLoadingGradient: linear-gradient(-45deg, #075060, #0a635c, #075060, #0a635c);
$warningGradient: linear-gradient(to right, #f66038, #f99844);
$dangerGradient: linear-gradient(to right, #f31752, #fc4b4f);
$color-warning: #ffc409;
$spareColor: #1cb4bc;
$spareColorLight: #e2f7f7;
$darkTextColor: #383838;
$lightTextColor: #fff;
$errorTextColor: #ef4643;
$finePrintTextColor: #858585;
$chevronOnWhite: #a7a7a7;
$background-color: #fafafa;
$successColor: #00b53e;
$failureColor: #ff151f;
$lightTextColorOnWhite: #808080;
$lightBorderOnWhite: #e6e6e6;
$containerBackgroundOnWhite: #f2f2f2;
$lightBlueOnWhite: #f2fcfb;
$darkLightBlueOnWhite: #e2f7f7;
$whiteUIHeader: #f7f7f7;
$backdropColor: #383838;
$cardOnLightBackground: #fff;
$reversalColor: #ef4643;
$dark-medium-color: #94a0a0;

$boxShadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
$boxShadowOnWhite: 0px 3px 10px rgba(0, 0, 0, 0.1);

$tutorialGradient3: linear-gradient(to right, #24b4c2, #4886dc);
$tutorialGradient4: linear-gradient(to right, #4983dd, #5146f0);
$tutorialGradient5: linear-gradient(to right, #5435ea, #5d39a7);

$mainAppGradientEnd: #00c7b8;
$mainAppGradientTop: #00a0c3;

$borderRadius: 2vw;

$colors: (
  primary: #387ef5,
  secondary: #32db64,
  danger: #f53d3d,
  light: #f4f4f4,
  dark: #222,
  spareColor: #1cb4bc,
  mainAppGradientTop: #00a0c3,
  textPrimary: #fff,
);

$color-grey: #666;
$color-light-grey: #cccccc;
$color-super-light: #f6f7f7;
$border-color: #e4e9f2;
