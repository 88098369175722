@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';
@import '@nebular/theme/styles/global/breakpoints';

@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~quill/dist/quill.core.css';
// @import '~bootstrap/scss/bootstrap-grid';
// @import '~bootstrap/scss/bootstrap-utilities';
// @import '~bootstrap/scss/bootstrap-reboot';

@import 'themes';
@import './colors.scss';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();
}

.nb-theme-default nb-layout.window-mode nb-layout-header.fixed {
  top: 0 !important;
  max-width: unset !important;
}

html {
  font-size: 14px;
}

strong {
  font-weight: 600 !important;
}

nb-auth-block {
  max-width: unset !important;
}

nb-sidebar.menu-sidebar {
  margin-top: 0 !important;
}

ngx-header {
  display: flex;
  align-items: center;
}

.menu-items.expanded {
  max-height: 1000px !important;
}

nb-user {
  cursor: pointer;
}

.nb-theme-default nb-menu.context-menu {
  border: 1px solid #1cb4bc;

  .menu-items .menu-item {
    a {
      &:focus,
      &:hover,
      &:active {
        &,
        nb-icon {
          background: #1cb4bc;
          color: white;
        }
      }
    }
  }
}

input[type='radio'],
input[type='checkbox'] {
  margin-left: 1.125rem;
}

.nb-menu {
  color: #a4abb3;
  font-weight: 600;
}

.horizontalFlex {
  flex-direction: row !important;
}

.main-content {
  background-color: #fafafa;
}

.clickable {
  cursor: pointer;
}

/* Over ride small modal position */

nb-dialog-container > * > nb-card {
  width: fit-content;
  min-width: 40vw;
  max-width: 80vw;

  height: fit-content;
  max-height: 82vh;

  @include media-breakpoint-down(sm) {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;

    height: 100vh !important;
    max-height: 100dvh !important;
  }
}

$dialog-sizes: (
  fit: fit-content,
  xxs: 20vw,
  xs: 30vw,
  sm: 40vw,
  md: 50vw,
  lg: 60vw,
  xl: 70vw,
  xxl: 80vw,
);

@each $name, $size in $dialog-sizes {
  nb-dialog-container nb-card.dialog-#{$name} {
    width: #{$size};
    min-width: #{$size};
    max-width: #{$size};
  }

  nb-dialog-container nb-card.dialog-up-#{$name} {
    width: fit-content;
    min-width: fit-content;
    max-width: #{$size};
  }

  nb-dialog-container nb-card.dialog-down-#{$name} {
    width: fit-content;
    min-width: #{$size};
    max-width: fit-content;
  }
}

nb-stepper .step-content {
  overflow-x: auto;
}

#toast-container > div {
  width: 500px !important;
  opacity: 1;
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey */
  border-top: 4px solid $spare-color;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ion-chevron-left {
  transform: rotate(180deg);
}

.pac-container {
  z-index: 1050;
}

.popover {
  //width: 3rem;
  top: auto !important;
  left: auto !important;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100% !important;
}

a.ng-star-inserted {
  padding: 0.375rem 1rem !important;
}

.user-context-menu {
  border: 2px solid $spare-color !important;
  background: #ffffff;
}

.inactiveText {
  margin-bottom: 0rem;
  margin-left: 1.125rem;
}

li.arrow {
  border-bottom-color: $spare-color !important;
}

.nb-theme-default nb-user .user-context-menu ul > li.arrow {
  border-bottom: 11px solid $spare-color !important;
}

.nb-theme-default .btn.btn-danger {
  background-color: white;
  color: $danger-color;
  border: solid 1px $danger-color;
}

.nb-theme-default ng2-smart-table thead tr.ng2-smart-filters th .ng2-smart-filter input {
  &:focus {
    border: 2px solid $spare-color !important;
  }
}

.nb-theme-default ng2-smart-table table tr th a {
  color: $darkTextColor;
}

.user-picture.image {
  background-size: 150% !important;
  background-position: 50% 50% !important;
}

.nb-theme-default .form-control,
.nb-theme-default .input-group-addon {
  border-radius: 2rem !important;
}

.nb-theme-default .btn.btn-danger .activateButton:hover {
  background-color: $spare-color !important;
  color: #ffffff !important;
}

.backButton {
  background-color: transparent;
  color: #a4abb3;
  font-weight: 600;
  font-size: 1.25em;

  :hover {
    color: $dark-color !important;
  }
}

.backButton:hover {
  color: $dark-color !important;
}

.nb-theme-default .btn {
  cursor: pointer !important;
}

input[type='checkbox'] {
  zoom: 1.5;
}

.nb-theme-default nb-layout.window-mode .scrollable-container {
  max-width: 1000000px !important;
}

.nb-theme-default nb-layout.window-mode .layout nb-layout-header nav {
  max-width: 1000000px !important;
}

.nb-theme-default nb-layout.window-mode {
  padding-top: 0rem !important;
}

.user-title {
  max-width: 10rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flex-centered {
  max-width: 100% !important;
  margin: 0rem !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  flex: auto !important;
}

app-register {
  width: 100% !important;
}

div.page-warning {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7f7f7;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  gap: 10px;

  p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 1rem !important;
    color: #666666 !important;
  }

  &.warning {
    border: none !important;
    background-color: $color-warning !important;

    p {
      color: #383838 !important;
      font-weight:600;
    }

    nb-icon {
      color: #383838 !important;
    }
  }

  &.danger {
    border: none !important;
    background-color: $danger-color !important;

    p {
      color: white !important;
      font-weight:600;
    }

    nb-icon {
      color: white !important;
    }
  }
}

.infoInstructionBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f7f7f7;
  align-items: center;
  border: 2px solid #e7e7e7;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;

  p {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 1rem !important;
    color: #666666 !important;
  }

  button {
    margin-right: 2rem;
    box-shadow: none;
    border: 1px solid #bfbfbf !important;
    background-color: transparent !important;
    border-radius: 28px !important;
    color: #666666 !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    font-size: 0.8rem !important;
    padding: 0.5rem 0.75rem !important;
  }

  .viewTourAndCloseContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    nb-icon[icon='info-outline'] {
      margin-right: 1rem;
    }
  }
}

// Override nb checkbox style to match spare schema.
.custom-control-indicator {
  border-color: $spare-color !important;
  background-color: $background-color !important;
}

.custom-control-indicator::before {
  border-color: $spare-color !important;
}

//  *******************
ngx-modal {
  overflow: hidden;
}

button.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  border: none;
  background: none;
}

// Adding red border on the input that has any validation error.
.validation-error.form-control {
  border: 2px solid $danger-color !important;
}

// disable outline in  spare dropdown component
spare-dropdown:focus {
  outline: none;
}

.small-badge {
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: 0 1px 2px #0000001a;
  border-radius: 10px;
  padding: 1px 9px;
  max-width: fit-content;
  display: inline;
}

// Adding mat calender vew over modals
.cdk-overlay-container {
  z-index: 999999999999999999 !important;
}

// Disable outline in buttons
button:focus {
  outline: none;
}

// Add Button style
button {
  text-transform: capitalize !important;
}

.buttonChip {
  display: flex;
  padding: 0px;
  height: 30px;
  width: 30px;
  font-size: 16px;
  line-height: 40px;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: 1rem;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  box-shadow: 0px 3px 16px rgba(0, 0, 0, 0.16);
  color: #ffffff;

  &.dark {
    background: #383838;
  }

  &.downloadSuccess {
    background: linear-gradient(to right, #40dc64, #40dc98);
  }

  &.downloadSuccess:hover {
    background: linear-gradient(to right, #40dc64, #40dc98);
  }

  p {
    margin-top: auto;
    margin-bottom: 0rem;
    color: #ffffff;
  }

  &.disabled {
    background: rgba(236, 236, 236, 0.712) !important;
    pointer-events: none;
    color: grey;
  }
}

.buttonChip:hover {
  background: $spare-color;
}

ngb-modal-window.overflowVisible ngx-modal {
  overflow: visible;
}

// This classes should apply on menu items

.edit-menu-item {
  i {
    font-size: 1.3rem;
    margin-right: 11px;
  }
}

.delete-menu-item {
  color: $danger-color !important;

  i {
    font-size: 1.3rem;
    margin-right: 11px;
  }
}

.menu-item {
  i {
    font-size: 1.3rem;
    margin-right: 11px;
  }
}

// Center all action loaders in the application
.loader-row {
  display: flex;
  justify-content: center;
}

hr.sectionSeperator {
  width: 100%;
}

form.standardForm {
  display: flex;
  flex-direction: column;
  width: 100%;

  div.formSection {
    width: 100%;
    display: flex;
    flex-direction: column;

    p.formSectionTitle {
      font-size: 1.2rem;
      font-weight: 600;
    }

    div.formFields {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
}

div.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  width: 100%;
  margin: 0 0 15px 0;

  div.error {
    margin-top: 5px;

    small {
      padding: 0 0 0 5px;
    }
  }
}

label.spareInputLabel {
  font-size: 1rem;
  width: auto;
  padding: 0 0 5px 5px;
}

input.spareInput,
textarea.spareInput,
select.spareInput {
  border: 2px solid transparent !important;
  background-color: #fafafa !important;
  width: auto;
  min-width: 300px;
  margin: 0;

  &:focus {
    border: 2px solid $spare-color !important;
  }

  &:disabled {
    background-color: white !important;
    padding: 0 0 0 5px;
    font-weight: 600;
  }
}

button.mainActionButton {
  min-width: 100px;
  display: flex;
  justify-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

p {
  &.normal {
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  &.medium {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  &.smaller {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0;
  }
}

.seperator.vertical {
  width: 1px;
  height: 100%;
  background: #c3c9c9;
  border-radius: 50px;
  margin: 0 15px;
  opacity: 0.5;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

ng2-smart-table {
  ng2-smart-table-pager {
    display: flex !important;
    justify-content: center !important;
    align-items: center;

    .ng2-smart-page-item {
      align-self: center;
    }
  }
}

nb-stepper {
  .header {
    padding: 25px;
  }
}

.inline-between {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

//Quill editor styles
.ql-snow a {
  color: $spare-color !important;
}

.bottom-sticky {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
}

nb-stepper.add-request-stepper {
  .header {
    width:600px;
    max-width: 85%;
    margin:auto;
    padding: 20px 0 0 0;
  }

  .step-content {
    padding: 0;
  }
}


.p-togglebutton.p-button {
  padding: 3px 8px;
  background: $spare-light;
  border: 1px solid $spare-hover;
  font-size: 0.8rem;
  &:not(.p-disabled):not(.p-highlight):hover {
    background: $spare-hover;
    border-color: $spare-hover;

    .p-button-icon-left, .p-button-icon-right {
      color: $spare-color;
    }
  }
  &.p-highlight {
    background: $spare-color !important;
    border-color: $spare-color !important;
  }

  .p-button-icon-left, .p-button-icon-right {
    color: $spare-hover;
  }
}

.p-tag {
  background: $spare-color;
}


nb-menu {
  .menu-group {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .menu-item a .menu-title {
    flex: none !important;
  }
}


//prime ng styles
button.p-button {
  border-radius: 100px;
  border: 0;
  box-shadow: none !important;

  &.margin-top {
    margin-top: 10px;
  }

  &.margin {
    margin: 10px;
  }

  &.danger-outline {
    background-color: transparent;
    color: $danger-color;
    border: 1px solid $danger-color;

    &:enabled:active {
      background-color: $danger-color;
      color: white;
      border: 1px solid $danger-color;
    }

    &:enabled:hover {
      background-color: $danger-color;
      color: white;
      border: 1px solid $danger-color;
    }
  }

  &.primary {
    background: $mainAppGradient;
    color: white;
    border:0;

    &:enabled:active {
      background: $mainAppGradientHover;
      border:0;
    }

    &:enabled:hover {
      background: $mainAppGradientHover;
      border:0;
    }
  }

  &.dark {
    background: $dark-color;
    color: white;
    border:0;

    &:enabled:active {
      background: $dark-color;
      border:0;
    }

    &:enabled:hover {
      background: $dark-color;
      border:0;
    }
  }

  &.round-icon {
    border-radius: 50%;
    padding: 0.5rem;
    height: 40px;
    width: 40px;
  }
}

.p-dialog {
  border-radius: 10px;

  .p-dialog-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .p-dialog-content:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .p-dialog-footer {
    padding:0;
  }
}

.p-dropdown, .p-inputtext, .p-inputtextarea {
  background: var(--input-basic-background-color) !important;
  border-radius: 20px !important;

  &:not(.p-disabled):hover {
    border-color: $spare-color;
  }

  &:enabled:focus, &:not(.p-disabled).p-focus {
    border-color: $spare-color;
    box-shadow: none;
  }
}

p-autocomplete.global-search-input {
  input {
    border-radius: 10px !important;
  }
} 

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $spare-hover;
  color: $spare-color;
}